'use client'

import { useState, useEffect } from 'react'

import { useThrottle } from './use-throttle'

type WindowSize = {
  width?: number
  height?: number
}

const DefaultWindowSize = {
  width: undefined,
  height: undefined,
}

/**
 * @param delay throttling delay in milliseconds
 */
export const useWindowSize = (delay = 100) => {
  const [windowSize, setWindowSize] = useState<WindowSize>(DefaultWindowSize)

  const handleResize = useThrottle(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, delay)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return windowSize
}
