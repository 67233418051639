import { useCallback, useRef } from 'react'

export const useThrottle = <T extends unknown[]>(
  callback: (...args: T) => void,
  limit: number,
) => {
  const lastCallRef = useRef(0)
  const callbackRef = useRef(callback)

  return useCallback(
    (...args: T) => {
      const now = Date.now()

      if (limit <= 0) {
        callbackRef.current(...args)
        return
      }

      if (now - lastCallRef.current >= limit) {
        lastCallRef.current = now
        callbackRef.current(...args)
      }
    },
    [limit],
  )
}
