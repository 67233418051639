'use client'

import { useWindowSize } from './use-window-size'

export enum DeviceBreakpoints {
  Lg = 'Lg',
  Md = 'Md',
  Sm = 'Sm',
}

export const BreakpointValues = {
  [DeviceBreakpoints.Lg]: 1024,
  [DeviceBreakpoints.Md]: 768,
  [DeviceBreakpoints.Sm]: 640,
}

export enum Direction {
  Min = 'MIN',
  Max = 'MAX',
}

type UseBrowserMediaQueryProps = {
  direction?: Direction
  breakpoint: DeviceBreakpoints
}

/**
 * @param breakpoint is a predefined device breakpoint: Lg, Md, Sm
 * @param direction is either css media query `max-width` or `min-width`
 */
export const useBrowserMediaQuery = ({
  breakpoint,
  direction = Direction.Max,
}: UseBrowserMediaQueryProps) => {
  const { width: windowWidth } = useWindowSize()

  const isBelowBreakpoint = direction === Direction.Max

  return (
    typeof windowWidth === 'number' &&
    (isBelowBreakpoint
      ? windowWidth < BreakpointValues[breakpoint]
      : windowWidth > BreakpointValues[breakpoint])
  )
}
