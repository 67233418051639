export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export interface RestHeaders {
  storeCode?: string
  token?: string
  xApiKey?: string
}
interface Error {
  message: string
}

export const restFetch = async <RESPONSE, PROPS = void>(
  url: string,
  method: HttpMethod = HttpMethod.GET,
  headers: RestHeaders = {},
  data?: PROPS,
): Promise<{ data?: RESPONSE; error?: Error }> => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(headers.storeCode ? { Store: headers.storeCode } : {}),
        ...(headers.xApiKey ? { 'x-api-key': headers.xApiKey } : {}),
        ...(headers.token ? { Authorization: `Bearer ${headers.token}` } : {}),
      },
      body: data ? JSON.stringify(data) : undefined,
    })

    if (!response.ok) {
      const error = (await response.json()) as Error
      return { error }
    }

    const responseData = (await response.json()) as RESPONSE
    return { data: responseData }
  } catch (error) {
    throw new Error(`Network error: ${(error as Error).message}`)
  }
}
